import { v4 as uuid } from 'uuid';

import './DownloadLinks.scss';

import appStoreImg from '../../assets/images/appstore.png';
import googlePlayImg from '../../assets/images/googleplay.png';

const DownloadLinks = props => {
  const links = [
    {
      id: uuid(),
      className: 'app-store',
      href: 'https://apps.apple.com/ru/app/%D1%81hin-%D1%81hin/id1535815120',
      img: appStoreImg,
      alt: 'app store'
    },
    {
      id: uuid(),
      className: 'google-play',
      href: 'https://play.google.com/store/apps/details?id=club.eatery.chinchin',
      img: googlePlayImg,
      alt: 'google play'
    },
  ];

  return (
    <ul className="download-links">
      {
        links.map(link => {
          const { id, className, href, img, alt } = link;

          return (
            <li key={id} className={`download-link ${className}`}>
              <a href={href} target="_blank" rel="noreferrer">
                <img src={img} alt={alt} />
              </a>
            </li>
          );
        })
      }
    </ul>
  );
};

export default DownloadLinks;
