import './Button.scss';

import { BTN_BG_COLOR } from '../../../shared/constants';

const Button = props => {
  const { className, color, children, clicked } = props;

  const btnClassName = ['btn'];

  if (className) {
    btnClassName.push(className);
  }

  switch (color) {
    case BTN_BG_COLOR.YELLOW:
      btnClassName.push(BTN_BG_COLOR.YELLOW);
      break;
    case BTN_BG_COLOR.RED:
      btnClassName.push(BTN_BG_COLOR.RED);
      break;
    default: break;
  }

  return (
    <button
      className={btnClassName.join(' ')}
      onClick={clicked}
    >
      {children}
    </button>
  );
};

export default Button;
