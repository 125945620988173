import SubTitle from '../UI/SubTitle/SubTitle';
import Title from '../UI/Title/Title';
import Description from '../UI/Description/Description';
import DownloadLinks from '../DownloadLinks/DownloadLinks';

import './Footer.scss';
import rhombusSmallImg from '../../assets/images/rhombus-small.png';
import rhombusLargeImg from '../../assets/images/rhombus-large.png';

const renderFooterCenter = () => (
  <div className="footer-center">
    <SubTitle>в один клік</SubTitle>
    <Title>завантажуй додаток</Title>
    <Description>Більше ніж система лояльності у вашому смартфоні.</Description>
    <DownloadLinks />
    {renderRhombusImgs()}
  </div>
);

const renderFooterBottom = () => (
  <div className="footer-bottom">
    <p className="copyright">Copyright © 2021 by Chin Chin. All Rights Reserved</p>
    <p className="made-in">
      <a href="https://www.eatery.club/" target="_blank" rel="noreferrer">
        Made with ❤️ by <span>Eatery Club</span>
      </a>
    </p>
  </div>
);

const renderRhombusImgs = () => (
  <div className="rhombus-imgs">
    <img src={rhombusSmallImg} className="rhombus-small" alt="rhombus small" />
    <img src={rhombusLargeImg} className="rhombus-large" alt="rhombus large" />
  </div>
);

const Footer = props => {
  return (
    <footer>
      <div className="container">
        {renderFooterCenter()}
        {renderFooterBottom()}
      </div>
    </footer>
  );
};

export default Footer;
