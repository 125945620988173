import { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import MainPage from './containers/MainPage/MainPage';
import './App.scss';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

const App = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const routes = (
    <Layout {...props} windowDimensions={windowDimensions}>
      <Switch>
        <Route path="/" exact><MainPage {...props} windowDimensions={windowDimensions} /></Route>
        <Redirect to="/" />
      </Switch>
    </Layout>
  );

  return (
    <div className="app">
      {routes}
    </div>
  );
}

export default withRouter(App);
