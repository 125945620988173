import Logo from '../../components/Logo/Logo';
import SubTitle from '../../components/UI/SubTitle/SubTitle';
import Title from '../../components/UI/Title/Title';
import Button from '../../components/UI/Button/Button';

import './MainPage.scss';

import { BTN_BG_COLOR, MAX_WIDTHS } from '../../shared/constants';

const renderDesktopLogo = () => (
  <>
    <p className="name">Asian</p>
    <Logo />
    <p className="name">Cafe</p>
  </>
);

const renderMobileLogo = () => <Logo />;

const renderDeliverySubTitle = () => <SubTitle>Chin-chin delivery</SubTitle>;
const renderDeliveryTitle = () => <Title>Переглянь меню та замовляй доставку chin chin</Title>;

const renderFranchiseSubTitle = () => <SubTitle>Chin-chin business</SubTitle>;
const renderFranchiseTitle = () => <Title>Зроби перший крок для власного прибуткового бізнесу з CHIN CHIN</Title>;

const MainPage = props => {
  const { windowDimensions } = props;
  const { width } = windowDimensions;

  const deliveryHandler = () => window.open('https://delivery.chin-chin.ua/', '_blank');

  const franchiseHandler = () => window.open('https://franchise.chin-chin.ua', '_blank');

  const renderDeliveryBtn = () => <Button className="delivery" color={BTN_BG_COLOR.YELLOW} clicked={deliveryHandler}>Зробити замовлення</Button>;

  const renderFranchiseBtn = () => <Button className="franchise" color={BTN_BG_COLOR.RED} clicked={franchiseHandler}>Франшиза</Button>;

  const renderDektopVersion = () => (
    <>
      <p className="left-bg"></p>
      <p className="right-bg"></p>

      <div className="container">
        <div className="left-content">
          {renderDeliverySubTitle()}
          {renderDeliveryTitle()}
          {renderDeliveryBtn()}
        </div>
        <div className="right-content">
          {renderFranchiseSubTitle()}
          {renderFranchiseTitle()}
          {renderFranchiseBtn()}
        </div>
      </div>
    </>
  );

  const renderMobileVersion = () => (
    <>
      <div className="top-content">
        <div className="container">
          {renderDeliverySubTitle()}
          {renderDeliveryTitle()}
          {renderDeliveryBtn()}
        </div>
      </div>
      <div className="bottom-content">
        <div className="container">
          {renderFranchiseSubTitle()}
          {renderFranchiseTitle()}
          {renderFranchiseBtn()}
        </div>
      </div>
    </>
  );

  return (
    <div className="main-page">
      <div className="logo-wrapper">
        {width > MAX_WIDTHS.MOBILE ? renderDesktopLogo() : renderMobileLogo()}
      </div>
      <section className="main-info">
        {width > MAX_WIDTHS.MOBILE ? renderDektopVersion() : renderMobileVersion()}
      </section>
    </div>
  );
};

export default MainPage;