import { Link } from 'react-router-dom';

import logoImg from '../../assets/images/logo.png';
import './Logo.scss';

const Logo = props => (
  <div className="logo">
    <Link to="/">
      <img src={logoImg} alt="logo" />
    </Link>
  </div>
);

export default Logo;
