import Footer from '../../components/Footer/Footer';
import './Layout.scss';

const Layout = props => {
  const { children } = props;

  return (
    <>
      <main>
        {children}
      </main>

      <Footer />
    </>
  );
};

export default Layout;
